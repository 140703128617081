import 'bootstrap';
import './../scss/main.scss';
import $ from 'jquery';

var currentPath = window.location.pathname

if ( currentPath !== '/' ){
    $(".fixed-top").toggleClass('scrolled');
    $(".navbar-toggler-icon").toggleClass('scrolled');
    $(".nav-link").toggleClass('scrolled');
    $("#logo-navbar").toggleClass('scrolled');
    // Get Page name 
    var pageName = currentPath.slice(0, -1);
    pageName = pageName.substring(pageName.lastIndexOf('/') + 1).toLowerCase();
    $("body").toggleClass(pageName);
}
else {
    $(window).scroll(function(){
        $(".fixed-top").toggleClass('scrolled', $(this).scrollTop() > 50);
        $(".navbar-toggler-icon").toggleClass('scrolled', $(this).scrollTop() > 50);
        $(".nav-link").toggleClass('scrolled', $(this).scrollTop() > 50);
        $("#logo-navbar").toggleClass('scrolled', $(this).scrollTop() > 50);
    });
}
